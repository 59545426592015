import { RangeValue } from '../../components/Form/DateRangeInputField/DateRangeInputField';
import { IMontlyLoad } from '../../pages/Dashboard/reducers/DashboardReportsReducer';
import { IFetchDashoardStatsResponse } from '../../pages/Dashboard/reducers/DashboardStatsReducer';
import { API_CONSTANTS } from '../../services/apiConstants';
import { DriverUtils } from '../../utils/DriverUtils';
import { dateFormatter } from '../../utils/functions';
import { IGenericApiResponse } from '../../utils/interfaces';
import { apiCall } from '../axios';
import { IDriverCard } from '../driver/interfaces';
import { IShipment } from '../shipment/interfaces';

const fetchDashboardStats = async (): Promise<IFetchDashoardStatsResponse> => {
    const url = `${API_CONSTANTS.dashboard}${API_CONSTANTS.dashboard}`;

    const response: IGenericApiResponse<IFetchDashoardStatsResponse> =
        await apiCall('get', url, {}, true);

    return response.data;
};

const fetchDashboardReports = async (
    dateRange: RangeValue,
): Promise<IMontlyLoad[] | undefined> => {
    if (dateRange && dateRange[0] !== null && dateRange[1] !== null) {
        const url = `${API_CONSTANTS.dashboard}/reports/${dateFormatter(
            dateRange[0],
        )}/${dateFormatter(dateRange[1])}`;

        const response: IGenericApiResponse<IMontlyLoad[]> = await apiCall(
            'get',
            url,
            {},
            true,
        );

        return response.data;
    }
};

const fetchUpcomingShipments = async (): Promise<IShipment[]> => {
    const url = `${API_CONSTANTS.shipments}/odata?$orderby=DeliveryStartDate asc&$top=20&$filter=IsActive eq true and DeliveryStartDate ne null and Associate/Priority eq 'High' and ShipmentStatus/Name in ('ContinueBuilding','NeedsDrivers','Ready','AwaitingConfirmation')&include=Associate`;

    const response: IGenericApiResponse<IShipment[]> = await apiCall(
        'get',
        url,
        {},
        true,
    );

    return response.data;
};

const fetchReadyForBillingShipments = async (): Promise<IShipment[]> => {
    const url = `${API_CONSTANTS.shipments}/odata?include=Associate,ShipmentStatuses&$filter=IsActive eq true and ShipmentStatus/Name eq 'Delivered' and DeliveryEndDate ne null&$orderby=DeliveryEndDate asc`;

    const response: IGenericApiResponse<IShipment[]> = await apiCall(
        'get',
        url,
        {},
        true,
    );

    return response.data;
};

const fetchHighlightedDrivers = async (): Promise<IDriverCard[]> => {
    const url = `${
        API_CONSTANTS.associates
    }/odata?include=Address,TagGroups,Attachments,Destination,Unavailabilities&orderby=Name%20asc&$filter=(((WebApp.Data.Entities.Driver/Unavailabilities/any(u: u/StartDate le ${dateFormatter(
        new Date(),
    )})) and (WebApp.Data.Entities.Driver/Unavailabilities/any(u: u/EndDate ge ${dateFormatter(
        new Date(),
    )}))) or Attachments/any(a: a/Expiry le ${dateFormatter(
        new Date(),
    )}) or WebApp.Data.Entities.Driver/IsBrokenDown eq true) and (Discriminator eq 'Driver' or Discriminator eq 'Broker') and (IsActive eq true)`;
    const driverUtils = new DriverUtils();

    const response: IGenericApiResponse<IDriverCard[]> = await apiCall(
        'get',
        url,
        {},
        true,
    );

    return driverUtils.formatDriverList(response.data);
};

export const dashboardApi = {
    fetchDashboardStats,
    fetchDashboardReports,
    fetchUpcomingShipments,
    fetchReadyForBillingShipments,
    fetchHighlightedDrivers,
};
